
import * as customProviderRuntime$utRy8ZMgME from '/var/www/astondom-frontend/all/astondom-front-20240823083539/node_modules/sitis-nuxt-3/providers/imageProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imageProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imageProvider']: { provider: customProviderRuntime$utRy8ZMgME, defaults: {"modifiers":{"format":"webp"},"baseURL":"https://astondom.ru/api/storage/image/"} }
}
        